import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Quelques liens",
  "slug": "links"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Quelques liens techniques pour aller plus loin`}</h1>
    <h2>{`Mes truc et machins ailleurs que sur le site perceptible.fr`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://observablehq.com/@alainro"
        }}>{`Observable`}</a>{` absolument révolutionnaire à mon avis. Le Jupyter des javascript nerds. Il annule, remplace et généralise les deux précédents dispositifs spécialisés d3.`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://bl.ocks.org/AlainRo"
            }}>{`bl.ocks.org`}</a>{` le visualiseur de fragments de code hébergés sur github (gists)`}</li>
          <li parentName="ul">{`le défunt `}<a parentName="li" {...{
              "href": "https://blockbuilder.org/"
            }}>{`bl.ockbuilder`}</a>{` qui fut un magnifique outil pour partager du code d3.`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://toulouse-dataviz.fr/"
        }}>{`Toulouse dataviz`}</a>{` le site de l'association 1901 qui héberge les supports slides et `}<strong parentName="li">{`videos`}</strong>{` des meetups :`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.meetup.com/fr-FR/Meetup-Visualisation-des-donnees-Toulouse/"
        }}>{`Visualisation des données Toulouse`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.meetup.com/fr-FR/d3-js-Toulouse/"
        }}>{`d3.js Toulouse`}</a>{` `}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/AlainRo"
        }}>{`Github`}</a></li>
      <li parentName="ul">{`ainsi que le super événement `}<a parentName="li" {...{
          "href": "http://toulouse-dataviz.fr/hackaviz/2021-results"
        }}>{`Hackaviz`}</a></li>
    </ul>
    <h2>{`Quelques trucs utiles que j'aime bien`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://idyll-lang.org/"
        }}>{`Idyll`}</a>{` un markdown boosté avec React pour faire des `}<strong parentName="li">{`scrolly telling`}</strong>{` `}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/webpro/reveal-md"
        }}>{`reveal-md`}</a>{` pour faire rapidement de belles présentations à partir d'un fichier markdown.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.gatsbyjs.org/"
        }}>{`Gatsby`}</a>{` le générateur de site avec lequel celui ci est construit.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://d3js.org/"
        }}>{`d3js`}</a>{` rien de plus à dire.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://vega.github.io/vega-lite/"
        }}>{`Vega-Lite`}</a>{` un langage de description de visualisation. Un d3 statique sans programmation.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://notion.so/product"
        }}>{`Notion`}</a>{` un outil à TOUT faire que j'ai découvert début 2019 qui prend désormais en charge une grande partie de mon workflow personnel, professionnel et associatif. Il me sert aussi chez mes clients pour organiser les processus autour des tableaux de bord. J'ai écrit un billet `}<a parentName="li" {...{
          "href": "https://medium.com/@alain.roan/notion-so-un-cerveau-num%C3%A9rique-%C3%A0-plusieurs-87a09a7e6aa5"
        }}>{`Medium`}</a>{` sur cet outil.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      