import React from "react"
import Layout from "../components/layouts/AboutLayout"
import Seo from "gatsby-theme-blog-starter/src/components/seo/Seo"

import MD from './linksmd.md'

export default ({ data }) => (
  <>
    <Layout pageTitle="Liens utiles">
     <Seo 
      title="Liens utiles"
      description="Liens utiles"
      tags={["info"]}
      slug="/links" />
      <MD/>
    </Layout>
  </>
)
